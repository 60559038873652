/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from "./App";
import AuthedElement from "./auth/AuthedElement";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import defaultRoute from "./defaultRoute";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./wdyr";

const LabelView = React.lazy(() => import("./components/views/LabelView.js"));
const SearchView = React.lazy(() => import("./components/views/SearchView.js"));
const FieldView = React.lazy(() => import("./components/views/FieldView.js"));

ReactDOM.createRoot(document.querySelector("#root")).render(
  <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <Routes>
            <Route path="/*" element={<AuthedElement element={App} />}>
              <Route index element={<Navigate to={defaultRoute} replace />} />
              <Route path="claim/nested/:id" element={<LabelView />} />
              <Route path="claim/:id" element={<LabelView />} />
              <Route path="claim" element={<SearchView />} />
              <Route path="fields" element={<FieldView />} />
            </Route>
          </Routes>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
  </React.StrictMode>
);

// In some browser contexts, the service worker API is not available
if (navigator.serviceWorker) {
  let pendingNavUpdate = false;

  // If you want your app to work offline and load faster, you can register()
  // a service worker below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register({
    onUpdate: (registration, fromPageLoad) => {
      const installingWorker = registration?.waiting;
      if (fromPageLoad || pendingNavUpdate) {
        installingWorker?.addEventListener("statechange", (event) => {
          if (event.target.state === "activated") {
            if (fromPageLoad || pendingNavUpdate) {
              console.log("Activated service worker from page load.");
              window.location.reload();
            }
          }
        });
      }
      installingWorker?.postMessage({ type: "SKIP_WAITING" });
    },
  });

  // Whenever the user navigates, check for a service worker update
  window.onpopstate = window.onpushstate = (options) => {
    if (options.state === "anchor") {
      return;
    }

    // If we don't have a service worker yet, let registration handle it
    if (!navigator.serviceWorker.controller) {
      return;
    }

    // When they navigate through the app, take the opportunity to update
    let regPromise = navigator.serviceWorker.getRegistration();
    regPromise.then((registration) => {
      console.log("Checking for service worker update...");
      setTimeout(
        () =>
          registration.update().then(() => {
            pendingNavUpdate = (registration.installing ?? registration.waiting) !== null;
            if (pendingNavUpdate) {
              console.log("Service worker updated.");
              setTimeout(() => (pendingNavUpdate = false), 1000);
            }
          }),
        1
      );
    });
  };

  // Override pushState to emit event
  (function (history) {
    let pushState = history.pushState;
    history.pushState = function (state, unused, url) {
      if (typeof window.onpushstate == "function") {
        window.onpushstate({ state, url });
      }
      return pushState.apply(history, arguments);
    };
  })(window.history);
}
