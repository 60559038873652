import defaultRoute from "../defaultRoute.js";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import LogRocketUser from "./LogRocketUser";

const Auth0ProviderWithHistory = ({ children }: Readonly<PropsWithChildren>) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const connection = process.env.REACT_APP_AUTH0_CONNECTION;

  const navigate = useNavigate();

  let defaultUri = window.location.origin + defaultRoute;

  const onRedirectCallback = (appState: any) => {
    /* istanbul ignore next */
    navigate(appState?.returnTo || defaultUri);
  };

  return (
    <Auth0Provider
      domain={domain!}
      clientId={clientId!}
      redirectUri={defaultUri}
      onRedirectCallback={onRedirectCallback}
      audience="https://monocle-api.magmutual.com/"
      cacheLocation="localstorage"
      connection={connection}
    >
      <LogRocketUser>{children}</LogRocketUser>
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
