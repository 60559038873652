import { ComponentType } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import { withAuthenticationRequired} from "@auth0/auth0-react";

type ElementType = ComponentType & {readonly element: ComponentType};

function AuthedElement({ element }: Readonly<ElementType> ) {
  const loc = window.location;
  const load = () => {
    return <LoadingSpinner/>;
  };
  const ProtectedElement = withAuthenticationRequired(element, {
    onRedirecting: load,
    returnTo: loc.pathname + loc.search + loc.hash
  });

  return <ProtectedElement />;
}

export default AuthedElement;
