import {Toast, ToastContainer} from "react-bootstrap";

function AlertOverlay(props: any) {
  let show = props.show;
  let position = props.position ?? "top-center";
  let bg = props.bg;
  let closeable = props.closeable;
  let title = props.title;

  return (
    <ToastContainer position={position}>
      <Toast bg={bg} show={show}>
        <Toast.Header closeButton={closeable}>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body>
          {props.children}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default AlertOverlay;
