import React, {useRef} from "react";

export function useDeferredPromise() {
  const deferRef = useRef(null);

  function reset() {
    const deferred = {};

    deferred.promise = new Promise((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });
    deferRef.current = deferred;
  }

  if (deferRef.current === null) {
    reset();
  }
  return [deferRef, reset];
}
