import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import defaultRoute from "../defaultRoute";
import HierarchySelect from "./HierarchySelect";
import LogoutButton from "./Logout";
import SearchBar from "./SearchBar";

function NavBar() {
  let location = useLocation();

  return (
    <Navbar variant="dark" bg="dark">
      <Navbar.Brand as={Link} to={defaultRoute}>
        <img alt="MAGMUTUAL MONOCLE" src={"/logo.png"} />
      </Navbar.Brand>
      {!location.pathname.includes("nested") && (
        <>
          <Nav>
            <HierarchySelect />
            <Nav.Link as={NavLink} to="/claim">
              Claims
            </Nav.Link>
            <Nav.Link as={NavLink} to="/fields">
              Fields
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            <SearchBar />
            <LogoutButton />
          </Nav>
        </>
      )}
    </Navbar>
  );
}

export default NavBar;
