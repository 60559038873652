import {PropsWithChildren, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogRocket from "logrocket";
import setupLogRocketReact from 'logrocket-react';

function LogRocketUser({ children }: Readonly<PropsWithChildren>) {
  // Initialize Auth0
  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY!, {
        release: process.env.REACT_APP_VERSION,
      });
      LogRocket.identify(user.sub!, {
        name: user.name!,
        email: user.email!,
      });
      setupLogRocketReact(LogRocket);
    }
  }, [user]);

  return <>{children}</>;
}

export default LogRocketUser;
