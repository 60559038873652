import React, { useEffect, useState } from "react";
import { FormSelect } from "react-bootstrap";
import { useRecoilState } from "recoil";
import hierarchyState from "../state/hierarchy";
import { useQuery } from "@tanstack/react-query";
import { api } from "../util/fetch";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

export default function HierarchySelect() {
  let location = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const [hierarchy, setHierarchy] = useRecoilState(hierarchyState);
  let [hierarchies, setHierarchies] = useState(null);

  const levelsQuery = useQuery({
    queryKey: ["/levels/available"],
    queryFn: async ({ queryKey: [queryKey] }) => {
      const token = await getAccessTokenSilently();
      return await api(
        queryKey,
        (data) => {
          setHierarchies(data);
        },
        false,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
  });

  // If the above query fails, set the hierarchies to null
  /* istanbul ignore next */
  useEffect(() => {
    if (levelsQuery.isError) {
      setHierarchies(null);
    }
  }, [levelsQuery.error, levelsQuery.isError]);

  // Can't change a claim's hierarchy
  const disable =
    location.pathname.startsWith("/claim") && location.pathname.length > "/claim/".length;

  return (
    <>
      {hierarchies && (
        <FormSelect
          onChange={
            /* istanbul ignore next */
            (e) =>
              /* istanbul ignore next */
              setHierarchy(e.target.value)
          }
          value={hierarchy}
          disabled={disable}
        >
          {Object.entries(hierarchies).map(([h, n]) => (
            <option key={h} value={h}>
              {n}
            </option>
          ))}
        </FormSelect>
      )}
    </>
  );
}
