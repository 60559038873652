export const DATA_API = process.env.REACT_APP_DATA_API;

export async function checkFetch(res) {
  if (!res.ok) {
    let text = await res.json();
    /* istanbul ignore next */
    if (text.detail) {
      text = text.detail;
    }
    throw Error(`${res.statusText}: ${text}`);
  }
  return res.json();
}
/* istanbul ignore next */
export function api(url, cb, useErr, options) {
  return fetch(DATA_API + url, options)
    .then(checkFetch)
    .then((data) => {
      cb(data);
      return data;
    })
    .catch((err) => catchFetch(err, useErr ? cb : undefined));
}

export function post(url, cb, useErr, body, options) {
  return api(url, cb, useErr, {
    ...options,
    method: "POST",
    headers: {
      ...options?.headers,
      "Content-Type": "application/json;charset=UTF-8",
    },
    body,
  });
}

export function deleteApi(url, cb, useErr, body, options) {
  return api(url, cb, useErr, {
    ...options,
    method: "DELETE",
    headers: {
      ...options?.headers,
      "Content-Type": "application/json;charset=UTF-8",
    },
    body,
  });
}

/* istanbul ignore next */
export function catchFetch(err, cb) {
  console.error("Fetch error:", err);
  if (!cb) {
    throw err;
  }
  let e;
  if (err instanceof TypeError) {
    // Default fetch error (network error)
    e = "Could not connect to the API server. Please try again later.";
  } else {
    e = err + ". Please try again later.";
  }
  cb(null, {
    trace: err,
    msg: e,
  });
}
