import React, { Suspense, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";
import "./App.scss";
import NavBar from "./components/NavBar.js";
import LoadingSpinner from "./components/LoadingSpinner";
import AlertOverlay from "./components/AlertOverlay";
import { Button } from "react-bootstrap";
import { RecoilRoot } from "recoil";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 1000 * 60, // 5 minutes
      refetchOnMount: "always",
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

function App() {
  let [hasUpdate, setHasUpdate] = useState(false);

  useEffect(() => {
    // Listen to see if a new service worker has become active
    /* istanbul ignore next */
    function handleControllerChange() {
      console.log("New active service worker available.");
      setTimeout(() => setHasUpdate(true), 1002);
    }
    // In some browser contexts, the service worker API is not available
    /* istanbul ignore next */
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", handleControllerChange);
      return () =>
        navigator.serviceWorker.removeEventListener("controllerchange", handleControllerChange);
    }
  }, []);

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <NavBar />
        <AlertOverlay show={hasUpdate} title="Update Available" closeable={false}>
          <p>An update is available, please refresh the app to update after finishing your work.</p>
          <Button className="text-end" onClick={window.location.reload.bind(window.location)}>
            Update App
          </Button>
        </AlertOverlay>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
