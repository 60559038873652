import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, Nav, InputGroup, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { api } from "../util/fetch";
import { useAuth0 } from "@auth0/auth0-react";
import { useDeferredPromise } from "../useDeferredPromise";
import { useRecoilValue } from "recoil";
import hierarchyState from "../state/hierarchy";
import { Search } from "react-bootstrap-icons";

export default function SearchBar() {
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState("");
  const [diceInvis, setDiceInvis] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const [deferredRequestRef, reset] = useDeferredPromise();

  let hierarchy = useRecoilValue(hierarchyState);

  const renderOverlay = (props) => {
    return <Tooltip {...props}>Random Unlabeled Claim</Tooltip>;
  };

  const { status, data, refetch } = useQuery({
    queryKey: [`/claims/random?hierarchy=${hierarchy}`, { type: "nav" }],
    queryFn: async ({ queryKey: [queryKey] }) => {
      const token = await getAccessTokenSilently();
      return await api(queryKey, () => {}, false, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  // If the above query fails, set the dice to invisible and reject the deferred promise
  /* istanbul ignore next */
  useEffect(() => {
    if (status === "error") {
      setDiceInvis(true);
      deferredRequestRef.current.reject();
    }
  }, [status, deferredRequestRef]);

  /* istanbul ignore next */
  useEffect(() => {
    if (status === "success") {
      reset();
      deferredRequestRef.current.resolve(data);
      setDiceInvis(false);
    }
  }, [status, data]);

  function handleChange(event) {
    setSearchString(event.target.value);
  }

  function handleSubmit(event) {
    /* istanbul ignore next */
    event.preventDefault();
    if (searchString) {
      const newAddress = "/claim/" + searchString;
      navigate(newAddress);
    }
  }

  function handleClick() {
    /* istanbul ignore next */
    deferredRequestRef.current.promise
      .then((random) => {
        reset();
        const newAddress = "/claim/" + random.claim_no;
        navigate(newAddress);
      })
      .then(async () => {
        await refetch();
      });
  }

  return (
    <Nav className="text-center pe-md-2">
      <OverlayTrigger placement="bottom" animation={false} overlay={renderOverlay}>
        <Nav.Link className={"dice-btn" + (diceInvis ? " d-none" : "")} onClick={handleClick}>
          <img className="dice-btn" alt="Random Claim" src={"/dice.png"} />
        </Nav.Link>
      </OverlayTrigger>

      <Form className="form-search" onSubmit={handleSubmit}>
        <InputGroup size="fh">
          <Form.Control
            className="search-input"
            type="text"
            placeholder="Claim Number"
            onChange={handleChange}
            value={searchString}
          />
          <Button className="search-btn btn-outline-secondary" type="submit" variant="light">
            <Search />
          </Button>
        </InputGroup>
      </Form>
    </Nav>
  );
}
