import React from "react";
import defaultRoute from "../defaultRoute"
import { useAuth0 } from "@auth0/auth0-react";
import {NavLink} from "react-bootstrap";
import { BoxArrowRight } from 'react-bootstrap-icons';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const defaultUri = window.location.origin + defaultRoute;

  return (
    <NavLink onClick={() => logout({ returnTo: defaultUri })}>
      <BoxArrowRight />
    </NavLink>
  );
};

export default LogoutButton;
