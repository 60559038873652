import {Alert, Container} from "react-bootstrap";

export default function LoadingSpinner({ hidden = false, error = false, errorMsg = "An error occurred. Please try again later." }) {
  return (
    <div className={`text-center m-3 ${hidden ? "hidden" : ""}`}>
      <Container>
        {error ?
          <Alert variant="danger">{errorMsg}</Alert>
          :
          <div className="spinner-border loading-spinner" role="status">
            <span className="sr-only" />
          </div>
        }
      </Container>
    </div>
  );
}
